<template>
  <VContainer fluid fill-height class="loader-redirect">
    <img
      class="logo-container__logo"
      src="@/assets/images/socotec-logo-alpha.png"
      height="200px"
    />
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OidcCallback",
  data() {
    return {};
  },
  async mounted() {
    try {
      const redirectPath = await this.oidcSignInCallback();
      this.$router.push(redirectPath);
    } catch (err) {
      console.error(err);
      this.$router.push({ name: "DisplayError", params: { error: err } });
    }
  },
  methods: {
    ...mapActions("oidc", ["oidcSignInCallback"])
  }
};
</script>

<style lang="scss" scoped>
.loader-redirect {
  height: 100vh;
  background: #212432;

  .logo-container__logo {
    margin: auto;
    opacity: 1;
    animation: opacityblink 2s infinite;
  }
}

@keyframes opacityblink {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}
</style>
